<template>
<div class="Box">
  <div class="protocolBox">
   <textTitle  v-if="path === '/consult'" text="预约服务" />
    <div class="protocol">礼仪培训基地</div>
    <div class="Official">
      <div class="RcCodeBox">

        <img src="../../assets/img/qrCode/home.png" class="RcCode">
        <p>关注公众</p>
      </div>
      <div class="fomr">
        <el-form
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
          label-width="100px"
          class="demo-dynamic"
        >
          <el-form-item
            prop="enterpriseName"
            label="企业名称"
            :rules="[
      { required: true, message: '请输入企业名称', trigger: 'blur' },
    
    ]"
          >
            <el-input v-model="dynamicValidateForm.enterpriseName"></el-input>
          </el-form-item>
          <el-form-item
            label="联系人"
            prop="uesrName"
            :rules="[
                {required: true, message: '请输入联系人', trigger: 'blur'},
                
    ]"
          >
            <el-input v-model="dynamicValidateForm.uesrName"></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="phone"
            :rules="[
                {required: true, message: '请输入联系电话', trigger: 'blur'},
                
    ]"
          >
            <el-input v-model="dynamicValidateForm.phone"></el-input>
          </el-form-item>

          <el-form-item style=" text-align: center;">
            <el-button
              type="info"
              @click="submitForm('dynamicValidateForm')"
              style="background-color: #999999;"
              size="medium"
            >提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  <sidebar v-if="path === '/consult'"/>
</div>
</template>


<script>
import { homeService } from "@/api"
import sidebar from "@/components/sidebar/sidebar"
import textTitle from "@/components/headTail/textTitle";

export default {
  components: {
    sidebar,
    textTitle
  },
  data() {
    return {
      dynamicValidateForm: {
        uesrName: "",
        enterpriseName: "",
        phone: "",
      },
     path: this.$route.path
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
                businessName: this.dynamicValidateForm.enterpriseName,
                liaisonMan: this.dynamicValidateForm.uesrName,
                liaisonPhone: this.dynamicValidateForm.phone
          }
          homeService.addConsumerInto(data).then(res => {
            this.$message({
              message: "提交成功",
              type: "success",
              duration: 1 * 1000
            })
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.protocolBox {
  width: 800px;
  display: inline-block;
  .protocol {
    text-indent: 20px;
    padding: 10px 0;
  }
  .Official {
    overflow: hidden;
    .RcCodeBox {
      float: left;
      width: 140px;
      .RcCode {
        height: 140px;
        
        border: rgb(238, 238, 238) 1px solid;
      }
      p {
        padding: 20px 0;
        text-align: center;
      }
    }
    .fomr {
      width: 70%;
      float: left;
    }
  }
}
</style>